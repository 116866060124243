import { withPrefix } from "gatsby";
import React, { useState } from "react";
import styled from "styled-components";


// const { src, caption } = data.player

const PlayerContainer = styled.div`
  max-width: 74rem;

  @media (max-width: 1600px) {
    max-width: 40rem;
    margin-left: auto;
    margin-right: auto;
  }

  margin-top: 2rem;
  padding: 0 2rem;
  z-index: 2;
  position: relative;
  text-align: center;

  @media (max-width: 750px) {
  }
`;

const Player = styled.section`
  position: relative;
  width: 100%;
  height: 0;
  padding-bottom: 56.56%;
  background-color: #150f28;
  z-index: 2;
  margin-bottom: 1rem;
  overflow: hidden;

  border: 1px solid #AC8FFF;
  filter: drop-shadow(0px 0px 10px #5E3EB9) drop-shadow(0px 0px 10px #5E3EB9);
  
  & iframe {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
  }
`;

const Button = styled.button`
  position: absolute;
  height: 100%;
  width: 100%;
  cursor: pointer;
  top: 0; left:0;

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  .play-btn {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%,-50%);
    width: 5rem;
    height: auto;
    opacity: 0.8;
    transition: 0.3s ease-out opacity;
  }

  &:hover .play-btn {
    opacity: 0.6;
  }


`

export default function EmbededPlayer({ src, thumbnail }) {
  const [canPlay, setCanPlay] = useState(false)

  const videoSrc = src ||
    `https://www.youtube.com/embed/uRvfGn5wWdc?controls=0&autoplay=1`
  const videoThumb = thumbnail || "/images/0-intro/trailer.jpg"

  return (
    <PlayerContainer>
      <Player>
        {!canPlay && <Button onClick={() => {
          setCanPlay(true)
        }}>
          <img alt="trailer" src={videoThumb} />
          <img className="play-btn" alt="play" src={withPrefix('/images/0-intro/play.svg')} />
        </Button>}

        {canPlay && (
          <iframe
            title="Skyweaver"
            src={videoSrc}
            frameBorder="0"
            allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen
          />
        )}
      </Player>
    </PlayerContainer>
  );
}
