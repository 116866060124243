import { data as lore, PRISMS } from "data/lore";
export const IMAGE_PREFIX = `/images/clash-of-inventors`;
export const VIDEO_PREFIX = `https://56utils.s3.ca-central-1.amazonaws.com/clash-of-inventors`;

// expand the prism info that's relevant to the expansion
const prismExpansion = [
  {
    altTitle: "Strength - Summon Giant Units!",
    text: "Strength has come to crush the competition, with numerous cards that make it easier than ever to summon and take advantage of massive creatures to overpower and crush your enemies",
    video: {
      url: VIDEO_PREFIX + "/str.mp4",
    },
  },
  {
    altTitle: "Agility - High Risk, Higher Rewards!",
    text: "Agility is holding nothing back in its quest to be the best! Agility will gain access to cards that reward it for spending its health aggressively, and damaging its own Hero to take the initiative!",
    video: {
      url: VIDEO_PREFIX + "/agi.mp4",
    },
  },
  {
    altTitle: "Wisdom - Wealth From Knowledge",
    text: "Wisdom never arrives to a competition unprepared, and will gain new cards that offer it rewards for maintaining a large hand size, allowing it to leverage its card generation abilities for further benefits",
    video: {
      url: VIDEO_PREFIX + "/wis.mp4",
    },
  },
  {
    altTitle: "Heart - Nothing is Wasted",
    text: "Heart is sticking to utilizing dead and dying Units to its advantage, but that doesn’t mean it hasn’t found new ways to innovate and overwhelm the competition, consuming its scrapped and destroyed Units to fuel its success!",
    video: {
      url: VIDEO_PREFIX + "/hth.mp4",
    },
  },
  {
    altTitle: "Intellect - Gadgets and Gizmos",
    text: "Intellect has come to the competition equipped with a veritable toolbox of synergies for low-cost Units, Spells, and Micron Drones galore, overwhelming opponents with a barrage of 1-cost trinkets and tools!",
    video: {
      url: VIDEO_PREFIX + "/int.mp4",
    },
  },
];


export const data = {
  landing: {
    title: "Clash of Inventors",
    image: {
      url: IMAGE_PREFIX + "/landing@2x.jpg",
      mobile: IMAGE_PREFIX + "/landing.jpg",
    },
    background: {
      url: IMAGE_PREFIX + "/header-text-bg@2x.jpg",
      mobile: IMAGE_PREFIX + "/header-text-bg.jpg",
    },
    textHTML: `<span style="margin-bottom:1rem; display: block">The greatest inventors gather for Kore City’s annual Festival of Innovation! With wondrous inventions they compete for the title of Architect of Invention!</span><span>Discover what it takes to compete and claim the title!</span>`,
  },
  newTrait: {
    title: "Dash",
    subtitle: "NEW TRAIT - DASH!",
    textHTML: `There's no time to waste with the new Dash Trait! Units with Dash cannot attack the enemy Hero on the turn it is summoned, but it can brawl with enemy units!`,
    points: [
      "More Aggressive Plays",
      "Attack Immediately",
      "Control the Board",
    ],
    video: { url: VIDEO_PREFIX + "/dash.mp4" },
  },
  drones: {
    title: "MICRON DRONES!",
    subtitle: "The Festival’s eager helpers!",
    textHTML: `<span style="margin-bottom:1rem; display: block;">
Help is never too far away thanks to the abundance of Micron Drones.</span>
 
    <span>These cute little bots are 1 cost 1/1 Units with the Dash Trait. They zip into battle and help deal with any problem you might have.</span>`,
    points: ["INCREASE YOUR SKILLS", "BE A PIONEER", "EXPAND YOUR REACH"],
    image: { url: IMAGE_PREFIX + "/drones.png" },
    background: {
      url: IMAGE_PREFIX + "/clash-drone-bg@2x.jpg",
      mobile: IMAGE_PREFIX + "/clash-drone-bg.jpg",
    },
  },
  subthemes: {
    subtitle: " Upgrade your Prism",
    title: "New Innovations for all the Prisms!",
    text: "Technology, robots and invention converge to enhance each Prism. Learn more about the new cards for each Prism and gameplay clips below!",
    background: {
      url: IMAGE_PREFIX + "/subthemes-bg@2x.jpg",
      mobile: IMAGE_PREFIX + "/subthemes-bg.jpg",
    },
    items: lore.prisms.content.map((prism, i) => {
      return {
        ...prismExpansion[i],
        title: prism.name,
        image: { url: prism.thumbnail },
      };
    }),
  },
  expansion: {
    title: "explore the full expansion pack",
    text: "See for yourself the vast possibilities that Clash of Inventors bring to the world of Skyweaver.",
    image: {
      url: IMAGE_PREFIX + "/card-circle.png",
    },
    background: {
      url: IMAGE_PREFIX + "/explore-cards-bg@2x.jpg",
      mobile: IMAGE_PREFIX + "/explore-cards-bg.jpg",
    },
    button: {
      title: "View All Cards",
      link: "/",
    },
  },
  join: {
    title: "Join the festival",
    text: "Join our Discord server and subscribe to our Subreddit to share your ideas. If you haven’t already, be sure to create a Skyweaver account now and check out all Sky has to offer!",
    image: {
      url: IMAGE_PREFIX + "/join-bg@2x.jpg",
      mobile: IMAGE_PREFIX + "/join-bg.jpg",
    },
    button: {
      title: "Play Now",
      link: "https://play.skyweaver.net",
    },
  },
};

