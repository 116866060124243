import React, { PureComponent } from "react";
import styled from "styled-components";

import SectionText from "../SectionText";
import { H2, H3 } from "../Typography";
import Image from "../Image";

const Section = styled.section`
  position: relative;
  overflow: visible;
  z-index: 21;
  display: flex;
  align-items: center;

  @media (max-width: 750px) {
    margin-top: 0vw;
    padding-top: 0rem;
    padding-bottom: 4rem;
    display: flex;
    flex-wrap: wrap;
    background-color: #0c061e;

    .section-text {
      order: 2;
      padding-top: 0rem;
      padding-bottom: 0rem;
    }
  }

  .playbutton {
    position: absolute;
    bottom: 20%;
    right: 25%;
    width: 250px;
    @media (max-width: 750px) {
      display: none;
    }
  }
`;

const Background = styled.div`
  max-height: 1200px;
  overflow: hidden;
  width: 100%;

  figure,
  img {
    width: 100%;
    z-index: 4;
  }


  @media (max-width: 750px) {
    height: unset;
    order: 1;
    margin-top: 0vw;
    position: relative;

    &::after {
      content: " ";
      position: absolute;
      bottom: -1%;
      left: 0;
      height: 30%;
      width: 100%;
      z-index: 21;
      background: linear-gradient(to top, #0c061e, rgba(12, 6, 30, 0));
    }
  }
`;

class SectionFeatureLeft extends PureComponent {
  render() {
    const {
      title,
      text,
      img,
      mobileImg,
      playButton,
    } = this.props;


    return (
      <>
        <Section>
          <SectionText
            mobilePosition="top"
            position="left"
            className={`section-text`}
          >
            <H2 color="#fff" uppercase>
              {title}
            </H2>
            <H3>{text}</H3>
          </SectionText>

          <Background>

            <Image
              width="853"
              height="528"
              mobileSrc={mobileImg}
              src={img}
            />
          </Background>

          {playButton && (
            <a
              href="https://beta.skyweaver.net"
              target="_blank"
              rel="noopener noreferrer"
              className="playbutton"
            >
              <Image src={playButton} />
            </a>
          )}
        </Section>
      </>
    );
  }
}

export default SectionFeatureLeft;
