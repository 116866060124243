import React, { useState } from "react"
import styled from "styled-components"

import { Autoplay } from 'swiper';

import { Swiper, SwiperSlide } from "swiper/react"
import 'swiper/css';
import 'swiper/css/autoplay';

import { P } from "components/Typography"


const ArrowSVG = () => <svg width="18" height="31" viewBox="0 0 18 31" fill="none" xmlns="http://www.w3.org/2000/svg">
	<path fillRule="evenodd" clipRule="evenodd" d="M10.5332 22.6843L3.41256 30.0016L1.86985 28.416L0.327149 26.8305L5.93362 21.0683L11.539 15.3061L5.95268 9.56345L0.366327 3.82301L1.88997 2.25703L3.41362 0.691065L10.5332 8.00836C14.4487 12.0327 17.6537 15.3344 17.6537 15.3463C17.6537 15.3572 14.4487 18.6589 10.5332 22.6843Z" fill="white" />
</svg>


const StyledQuote = styled.div`
	text-align: center;

	.quote-block {
		max-width: 48rem;
		margin: auto;
		margin-bottom: 2rem;		
	}

	figure {
		margin: auto;
		margin-bottom: 1.5rem;
	}
	
	.profile-image {
		height: 80px;
		width: 80px;
		border-radius: 50%;
		overflow: hidden;
		border: 1px solid var (--lilac);

		img {
			width: 100%;
			height: 100%;
			object-position: top;
			object-fit: cover;
		}
	}

	.regular-image {
		width: 200px;
		
		img {
			width: 100%;
		}
	}

	margin-bottom: 2rem;

	img {
		width: 100%;
	}
`
const Container = styled.div`
	width: 100%;
	max-width: 60rem;
	margin: auto;
	padding: 4rem;
	position: relative;

	button.prev, button.next {
		position: absolute;
		height: 100%;
		width: 3rem;
		top: 0;
		right: 0;
		transition: 0.3s ease-out opacity;

		&.prev {
			transform: scale(-1,1);
			left: 0;
			right: unset;
		}

		@media (max-width: 750px) {
			display: none;
		}

		&:hover{opacity: 0.67;}
		svg {width: 100%;}
	}
`


const Quote = ({
	text, label, image
}) => {
	return (
		<StyledQuote>
			<div className="quote-block">
				<P style={{
					fontStyle: "italic",
					fontWeight: "bold"
				}}>“{text}”</P>
			</div>

			<figure className={image && image.type === "profile" ? "profile-image" : "regular-image"}>
				<img alt={label} src={image.url} loading="lazy" />
			</figure>

			<div><P>- {label}</P></div>
		</StyledQuote>

	)
}

const Quotes = ({ quotes }) => {
	const [swiper, setSwiper] = useState(false)
	const [index, setIndex] = useState(0)


	return (
		<Container>
			<Swiper
				className="quotes-carousel"
				modules={[Autoplay]}
				autoplayEffect
				autoplay={{
					delay: 7000
				}}
				loop
				onSwiper={swiper => setSwiper(swiper)}
				onSlideChange={e => {
					setIndex(e.realIndex)
				}}
				speed={600}
			>
				{quotes?.map((quote, i) => <SwiperSlide><Quote {...quote} key={i} /></SwiperSlide>)}
			</Swiper>
			<button onClick={() => {
				swiper && swiper.slideNext()
			}} className="next">
				<ArrowSVG />
			</button>

			<button className="prev" onClick={() => {
				swiper && swiper.slidePrev()
			}}>
				<ArrowSVG />
			</button>

			<Dots>
				{quotes?.map((_, i) => <Dot active={i === index} key={i} />)}
			</Dots>

		</Container>
	)
}

export default Quotes


const Dots = styled.div`
	position: absolute;
	bottom: 3rem;left:50%;
	display: flex;
	flex-wrap: wrap;
	align-items: center; justify-content: center;
	transform: translateX(-50%);
`

const Dot = styled.span`
	height: 10px;width:10px;
	background-color: white;
	border-radius: 50%;
	margin:  0px 5px;
	opacity: ${props => props.active ? "1" : "0.6"};
	transition: 0.3s ease-out opacity;
`