import React, { Component } from "react";
import styled from "styled-components";
import Slider from "react-slick";

import CardImages from "./CardImages";
import Image from "../Image";

const TransitionHandler = styled.div`
  
  .slick-slide {
    height: 100%;
    overflow: visible !important;
    &,
    figure,
    .card-text span,
    .card-text p {
      transition: 0.2s ease-out opacity, 0.5s ease-out transform;
      opacity: 0;
      will-change: transform;
    }

    figure {
      // transform: translateY(50%);
    }
    .card-text span,
    .card-text p {
      transform: translateY(2rem);
    }
  }

  .slick-slide.slick-active {
    &,
    figure,
    .card-text span,
    .card-text p {
      opacity: 1;
    }

    figure,
    .card-text span,
    .card-text p {
      transform: translateY(-0%);
    }

    .card-text span {
      transition-delay: 0s;
    }
    .card-text p {
      transition-delay: 0.3s;
    }

    .card-images section:nth-of-type(1) figure {
      transition-delay: 0.2s;
    }
    .card-images section:nth-of-type(2) figure {
      transition-delay: 0.5s;
    }
    .card-images section:nth-of-type(3) figure {
      transition-delay: 0.8s;
    }
    .card-images section:nth-of-type(4) figure {
      transition-delay: 1.1s;
    }
  }
`;

const Section = styled.section`
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  max-width: 110rem;
  margin-top: 2rem;
  margin-left: auto;
  margin-right: auto;
  position: relative;
  padding-top: 100px;
  height: 100%;
  overflow: hidden;

  @media (max-width: 750px) {
    transform: translateX(0);
  }
  @media (max-width: 1000px) {
    padding-top: 20px;
  }
`;

const CardTextSection = styled.div`
  width: 50%;
  max-width: 30rem;
  padding: 2rem;
  text-align: left;
  z-index: 5;
  display: flex;
  flex-direction: column;
  min-height: 380px;

  @media (max-width: 1000px) {
    width: 100%;
    order: 1;
    padding: 0.5rem 0;
    width: 100%;
    transform: translateX(0);
    max-width: unset;
    min-height: 205px;
  }
`;

const Background = styled.div`
  position: absolute;
  bottom: 0;
  left: 0%;
  width: 65%;
  pointer-events: none;

  @media (max-width: 1000px) {
    width: 100%;
  }
`;

const HeroContainer = styled.div`
  width: 30vw;
  position: absolute;
  z-index: 3;
  min-width: 200px;
  max-width: 800px;
  right: 0px;
  top: -10px;
  img {
    -webkit-filter: drop-shadow(-50px 12px 25px rgba(19, 13, 33, 1));
    filter: drop-shadow(-50px 12px 25px rgba(19, 13, 33, 1));
  }

  @media (max-width: 1000px) {
    width: 60%;
    left: 20%;
    top: 210px;
    z-index: 1;
  }
`;

const PrismContainer = styled.span`
  width: 70px;
  position: relative;
  display: inline-block;
  @media (max-width: 1000px) {
    width: 100px;
  }
`;

const TitleGrid = styled.div`
  display: grid;
  grid-template-columns: 100px 1fr;
  border-bottom: 1px solid #40306b;
  align-items: center;
  padding-bottom: 10px;
  margin-bottom: 20px;
  @media (max-width: 1000px) {
    grid-template-columns: 110px 1fr;
    justify-content: center;
    border-bottom: none;
  }
`;

const GradientOverlay = styled.div`
  height: 100%;
  width: 100%;
  position: absolute;
  bottom: -1px;
  z-index: 4;
  background: linear-gradient(180deg, rgba(12, 6, 30, 0) 30%, #0c061e 100%);
  @media (max-width: 1000px) {
    background: linear-gradient(180deg, rgba(12, 6, 30, 0) 70%, #0c061e 100%);
  }
`;

const DesktopSubtitle = styled.p`
  display: inline-block;
  color: ${({ color }) => (color ? color : "#fff")};
  font-family: var(--font);
  letter-spacing: 0.01em;
  font-size: 1.2rem;
  font-weight: 400;
  line-height: 1.4;

  & b {
    font-weight: 600;
    color: var(--lilac);
  }
  @media (max-width: 1000px) {
    display: none;
  }
`;

const MobileSubtitle = styled.p`
  display: none;
  color: ${({ color }) => (color ? color : "#fff")};
  font-family: var(--font);
  letter-spacing: 0.01em;
  font-size: 1.2rem;
  font-weight: 400;
  line-height: 1.4;

  & b {
    font-weight: 600;
    color: var(--lilac);
  }
  @media (max-width: 1000px) {
    display: inline-block;
  }
`;

const PrismTitle = styled.p`
  color: ${({ color }) => (color ? color : "#fff")};
  font-family: var(--font);
  letter-spacing: 0.01em;
  font-size: 1.2rem;
  font-weight: 400;
  line-height: 1.4;
  font-size: 26px;
  font-weight: bold;
  font-family: Barlow Condensed;
  text-transform: uppercase;
  @media (max-width: 1000px) {
    font-size: 20px;
    font-weight: normal;
  }
`;

const CardText = ({ title, subtitle, prismImg }) => (
  <CardTextSection className="card-text">
    <TitleGrid>
      <PrismImage prismImg={prismImg} title={title} />
      <div>
        <PrismTitle color="var(--lilac)">{title}</PrismTitle>
        <MobileSubtitle>{subtitle}</MobileSubtitle>
      </div>
    </TitleGrid>
    <DesktopSubtitle>{subtitle}</DesktopSubtitle>
  </CardTextSection>
);

const HeroImage = ({ heroImg, title }) => (
  <HeroContainer>
    <Image src={heroImg} alt={title} style={{ width: "100%" }} />
  </HeroContainer>
);

const PrismImage = ({ prismImg, title }) => (
  <PrismContainer>
    <Image src={prismImg} alt={title} style={{ width: "100%" }} />
  </PrismContainer>
);

class CardSection extends Component {
  render() {
    return (
      <Section>
        <CardText {...this.props} />
        <CardImages {...this.props} />
        <div>
          <HeroImage {...this.props} />
        </div>
        <GradientOverlay />
      </Section>
    );
  }
}

export default class CardView extends Component {
  componentDidUpdate(prevProps) {
    if (
      this.props.selectedOptions[0] !== prevProps.selectedOptions[0] ||
      this.props.selectedOptions[1] !== prevProps.selectedOptions[1]
    ) {
      const _selectedOptions = [...this.props.selectedOptions];
      let sortedSelectedOptions = _selectedOptions.sort((a, b) => b - a);

      const index = this.props.cards.findIndex((card) => {
        return (
          sortedSelectedOptions[0] === card.arrayValue[0] &&
          sortedSelectedOptions[1] === card.arrayValue[1]
        );
      });
      this.slider.slickGoTo(index);
    }
  }

  render() {
    const { cards } = this.props;
    const settings = {
      speed: 300,
      fade: true,
      swipe: false

      // beforeChange: (current, next) => this.props.selectOption(next)
    };

    return (
      <TransitionHandler>
        <Slider
          style={{ overflow: "visible", position: "relative" }}
          ref={(slider) => (this.slider = slider)}
          {...settings}
        >
          {cards.map((card, i) => (
            <CardSection key={i} {...card} />
          ))}
        </Slider>

        <Background></Background>
      </TransitionHandler>
    );
  }
}
