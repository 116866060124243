import React from "react";
import { withPrefix } from "gatsby";
import styled from "styled-components";
import { P } from "../Typography";
import { MainContentMaxWidth } from "components/Layouts/MaxWidth";

import { data } from 'data/expansion-pack/hexbound-invasion'

export default function HexboundHomepageSection() {
  const { text } = data.homepage

  return (
    <Div>
      <ExpansionBadge
        src={withPrefix("/images/clash-of-inventors/expansion-badge.png")}
      />

      <MainContentMaxWidth>
        <MaxWidth>
          <img
            className="logo"
            src={withPrefix("/images/hexbound-invasion/logo-small.png")}
            alt="Clash of Inventors"
          />
          <P>
              {text}
          </P>

          <ViewBtn
            href="/hexbound-invasion"
          >
            <img
              src={withPrefix("/images/clash-of-inventors/view-expansion.png")}
            />
          </ViewBtn>
        </MaxWidth>
      </MainContentMaxWidth>
    </Div>
  );
}

const Div = styled.div`
  position: relative;
  padding: 4rem 0;
  background-image: url(${withPrefix("/images/hexbound-invasion/homepage-section-bg.png")});
  background-size: cover;
  background-position: center;
  @media (max-width: 700px) {
    background-color: #014fc4;
    padding-bottom: 2rem;
    padding-top: 8rem;
    background-image: url(${withPrefix("/images/hexbound-invasion/homepage-section-bg-mobile.png")});
  }
`;


const ViewBtn = styled.a`
  display: block;
  width: 500px;
  transform: translateX(-115px);
  margin-top: -80px;
  margin-bottom:-80px;
  transition: 0.3s ease-out opacity;

  &:hover {
    opacity: 0.7;
  }

  img {
    height: 100%;
    object-fit: cover;
    width: 100%;
  }

  @media (max-width: 700px) {
    width: 400px;
    left: 50%;
    position: absolute;
    bottom: 0;
    transform: translate(-50%);
  }
`;

const MaxWidth = styled.div`
  position: relative; z-index: 2;
  max-width: 38rem;
  margin-right: auto;
  padding: 4rem;

  .logo {
    max-width: 28rem;
    margin-left: -4%;
    margin-bottom: -6%;
  }

  @media (max-width: 700px) {
    padding: 0;
    padding-bottom:4rem;
    text-align: center;
    margin: 0 auto;

    .logo {
      margin: auto;
      display: block;
      width: 80%;
      margin-bottom: -5%;
      text-align: center;
    }
  }
`;

const ExpansionBadge = styled.img`
  width: 20rem;
  position: absolute;
  top:0;right:0;

  @media (max-width: 700px) {
    width: 10rem;
  }
`
