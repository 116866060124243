import React from "react";
import styled, { css } from "styled-components";

const Gradient = styled.div`
  position: absolute;
  ${({ gradientDirection }) => (gradientDirection ? gradientDirection : "bottom")}: 0;
  left: 0;
  width: 100%;
  z-index: 5;
  pointer-events: none;
  height: 13rem;
  opacity: ${({ opacity }) => (opacity ? opacity : 1)};
  background: linear-gradient(
    to ${({ gradientDirection }) => (gradientDirection ? gradientDirection : "bottom")},
    rgba(21, 15, 40, 0),
    #0c061e
  );

  @media (max-width: 750px) {
    bottom: -2px;
    height: 20rem;
    ${({ hideOnMobile }) =>
    hideOnMobile &&
    css`
        display: none;
      `}
  }
`;

const Section = ({ height, gradientDirection = "bottom", hideOnMobile = false }) => (
  <Gradient className="gradient" hideOnMobile={hideOnMobile} gradientDirection={gradientDirection} height={height} />
);

export default Section