import React, { Component } from 'react'
import styled from 'styled-components'

import Footer from '../Footer'
import Form from './Form'
import { H2, P } from '../Typography'
import Image from '../Image'

const Section = styled.section`
  height: 50rem;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 4rem;
  overflow: hidden;

  @media (max-width: 750px) {
    overflow: visible;
    height: unset;
    padding: 0rem;
    padding-top: 8rem;
    flex-wrap: wrap;
    align-items: unset;
    background-color: #00001a;
  }
`

const TextSection = styled.div`
  position: relative;
  z-index: 2;
  text-align: center;
  padding-bottom:4rem;

  p {
    max-width: 34rem;
    margin: auto;
  }

  @media (max-width: 750px) {
    width: 100%;
    padding: 0rem 0.5rem;
    
    h2 {padding: 0rem 3rem;}
    p {
      padding: 0rem 2rem;
    }
  }
`

export const Background = styled.div`
  height: ${({ height }) => height ? height : '100%'};
  width: 100%;
  position: absolute;
  top: ${({ top }) => top ? top : '0'}; 
  left: 0;

  figure, img {height: 100%; width: 100%; object-fit: cover;}

  @media (max-width: 750px) {
    height: 20rem;

    &::after {
      content: ' ';
      position: absolute;
      bottom: -1%;
      left: 0;
      height: 30%;
      width: 100%;
      z-index: 1;
      pointer-events: none;
      background: linear-gradient(to top, #000018, rgba(0,0,0,0))
    }
  }
`

class SectionEarlyAccess extends Component {
  render() {
    const { title, subtitle } = this.props
    return (
      <Section id='early-access'>
        <TextSection>
          <H2 style={{
          }} color='#fff' uppercase>{title}</H2>
          <P>
            <span dangerouslySetInnerHTML={{ __html: subtitle }} />
          </P>
          <Form />
        </TextSection>

        <Footer windowWidth={this.props.windowWidth} />

        <Background id='footer-background'>
          <Image src='/images/6-early/background.jpg' />
        </Background>
      </Section>
    )
  }
}

export default SectionEarlyAccess