import React from 'react'
import styled, { css } from 'styled-components'

const SectionText = styled.div`
  z-index: 11;
  position: absolute;
  top: 50%;
  margin-left: auto;
  padding-left: 10rem;

  .content {
    padding-right: 1rem;
  }

  ${({ position }) => position === 'left' ? css`
    max-width: 40rem;
    left: 0;
    padding-left: 10rem;

    @media (max-width: 1300px) {
      padding-left: 5rem;
      max-width: 30rem
    }


    @media (max-width: 1024px) {
      max-width: 29rem;
    }

    @media (max-width: 800px){
      max-width: 25rem;
      padding-left: 3rem;
    }
    
    @media (max-width: 750px) {
      padding-left: 1rem;
      width: 100%;
      max-width: unset;
      transform: translate(0);
    }

  ` : css`
    max-width: 50rem;
    right: 0;
    padding-right: 10rem;

    @media (max-width: 1300px) {
      padding-right: 5rem;
      max-width: 45rem
    }

    @media (max-width: 1024px) {
      max-width: 39rem;
      padding-right: 4rem;
    }

    @media (max-width: 800px){
      max-width: 34rem;
    }

    @media (max-width: 750px) {
      padding-right: 1rem;
      max-width: unset;
      width: 100%;
    }
  `}

  transform: translateY(-50%);

  @media (max-width: 750px) {
    padding: 2rem;
    padding-right: 2rem;
    position: relative;
    top: 0;
    left: 0;
    transform: none;
  }

`

const Section = ({ children, position, mobilePosition }) =>
  <SectionText className='section-text' mobilePosition={mobilePosition} position={position}>
    <div className='content'>
      {children}
    </div>
  </SectionText>

export default Section