import React, { Component } from 'react';
import '../intersectionObserver'
import Observer from '@researchgate/react-intersection-observer';

const withIntersectionObserver = threshold => BaseComponent => {
  const displayName = BaseComponent.displayName || BaseComponent.name || 'Component';

  return class WithIntersectionObserver extends Component {
    static displayName = `withIntersectionObserver(${displayName})`;

    state = {
      isIntersecting: false,
      intersectionRatio: undefined
    };

    handleChange = ({ isIntersecting, intersectionRatio }) => {
      this.setState({ isIntersecting: isIntersecting && intersectionRatio >= threshold }, this.setState({ intersectionRatio }));
    };

    render() {
      return (
        <Observer onChange={this.handleChange} threshold={threshold}>
          <BaseComponent {...this.props} intersectionRatio={this.state.intersectionRatio} isVisible={this.state.isIntersecting} />
        </Observer>
      );
    }
  };
};

export default withIntersectionObserver