import React, { PureComponent } from 'react'
import styled from 'styled-components'

import Image from '../Image'

const Section = styled.section`
  width: 60%;
  display: flex;
  align-items: center;
  position: relative;
  z-index: 1;
  margin-top: -10%;

  section:nth-child(1) {
    width: 32%;
    z-index: 4;
    transform: rotate(-15deg) translateY(30%);
  }
  section:nth-child(2) {
    width: 30%;
    z-index: 3;
    transform: translateY(19%)
  }
  section:nth-child(3) {
    width: 28%;
    z-index: 2;
    transform: rotate(15deg) translateY(22%);
  }

  section:nth-child(4) {
    width: 26%;
    z-index: 1;
    transform: rotate(15deg) translateY(32%);
  }

  img {vertical-align: bottom}

  @media (max-width: 1000px) {
    width: 100%;
    order: 2;
    padding-top: 1rem;

      section:nth-child(1) {
        width: 43%;
        z-index: 4;
        transform: rotate(-15deg) translateY(32%);
      }
      section:nth-child(2) {
        width: 43%;
        z-index: 3;
        transform: rotate(-7deg) translateY(25%)
      }
      section:nth-child(3) {
        width: 43%;
        z-index: 2;
        transform: rotate(7deg) translateY(27%);
      }
      section:nth-child(4) {
        width: 43%;
        z-index: 1;
        transform: rotate(15deg) translateY(36%);
      }
    
  }

  @media (max-width: 1000px) {
    z-index: 2;
    padding-top: 150px;
  }
`

const Figure = styled.section`
  margin-left: ${({ offset }) => offset ? offset : '0%'};
  img {transition: 0.3s ease-out transform;}
`

// const Background = styled.div`
//   position: absolute;
//   bottom: 0;
//   left: 50%;
//   transform: translateX(-50%);
// `

const CardImage = ({ src, offset }) =>
  <Figure className='card-image' offset={offset}>
    <Image external src={src} />
  </Figure>

export default class CardImages extends PureComponent {
  render() {
    const { image0, image1, image2, image3 } = this.props
    return (
      <Section className='card-images'>
        <CardImage className='card-image' src={image0} />
        <CardImage className='card-image' offset={`-14%`} src={image1} />
        <CardImage className='card-image' offset={`-14%`} src={image2} />
        <CardImage className='card-image' offset={`-14%`} src={image3} />
      </Section>
    )
  }
}