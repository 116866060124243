import React from 'react'
import styled from 'styled-components'

import { P } from "components/Typography"
import { withPrefix } from 'gatsby'


const topRow = [
  {
    title: "windows",
    icon: "WindowsButton.png",
    link: "https://download-win.skyweaver.net"
  },
  {
    title: "mac",
    icon: "MacButton.png",
    link: "https://download-mac.skyweaver.net"
  },
  {
    title: "linux",
    icon: "LinuxButton.png",
    link: "https://download-linux.skyweaver.net"
  }
]

const bottomRow = [
  {
    title: "googleplay",
    icon: "GooglePlayButton.png",
    link: "https://download-android.skyweaver.net"
  },
  {
    title: "mac",
    icon: "AppStoreButton.png",
    link: "https://download-ios.skyweaver.net"
  }
]

const LogoEl = styled.div`
  height: 3.2rem;
  margin-right: 0.5rem;
  line-height: 3.5;
  transition: 0.3s ease-out opacity;

  &:hover {
    opacity: 0.5;
  }
  img {
    width: auto;
    height:100%;
    vertical-align: middle;
  }
`

const Container = styled.div`
  padding: 2rem 0;
`


const Logos = styled.div`
display: flex;
margin-bottom: 0.5rem;
`

const Logo = ({ title, icon, link }) =>
  <a target="_blank" rel="noopener noreferrer" href={link}>
    <LogoEl className='logo-el'>
      <img alt={title} src={withPrefix(`/images/1-about/${icon}`)} />
    </LogoEl>
  </a>


const Section = () =>
  <Container>

    <P style={{
      marginBottom: "0.5rem",
      fontWeight: "bold"
    }}>Download now and play free anywhere:</P>
    <Logos>
      {topRow.map(logo => <Logo {...logo} key={logo.title} />)}
    </Logos>
    <Logos>
      {bottomRow.map(logo => <Logo {...logo} key={logo.title} />)}
    </Logos>
  </Container>

export default Section