import React, { PureComponent } from 'react'
import styled from 'styled-components'

import { H3 } from '../Typography'
import Image from '../Image'

const Section = styled.section`
  position: relative;
  overflow: hidden;
  z-index: 10;
  
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;

  @media (max-width: 750px) {
    height: auto;
  }
`

const Background = styled.div`
  position: absolute;
  top: 0; left: 0;
  height: 100%; width: 100%;
  figure, img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
`

const Content = styled.div`
  padding: 4rem 0rem;
  max-width: 80rem;
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  z-index: 2;
  position: relative;

  @media (max-width: 750px) {
    padding: 2rem;
    padding-bottom:6rem;
  }
`

const SpellEl = styled.div`
  width: 25%;
  padding: 2rem 2.5rem;
  text-align: center;
  h3 {
    font-size: 1.5rem;
    text-transform: uppercase;
  }

  img {border-radius: 50%; margin-bottom: 2rem;}

  @media (max-width: 750px) {
    width: 50%;
    padding: 2rem 2rem;

    h3 {
      font-size: 1.2rem;
      text-transform: uppercase;
    }
  }
`

const Spell = ({ src, title }) =>
  <SpellEl>
    <Image src={src} alt={title} />
    <H3 color='#fff' uppercase>{title}</H3>
  </SpellEl>

class SectionSpells extends PureComponent {
  render() {
    const { spells } = this.props
    return (
      <>
        <Section>
          <Background>
            <img src='/images/spells/background.jpg' alt="" loading="lazy" />
          </Background>

          <Content>
            {spells.map((spell, i) => <Spell key={i} {...spell} />)}
          </Content>
        </Section>
      </>
    )
  }
}

export default SectionSpells