import React, { Component } from 'react'
import styled, { css } from 'styled-components'

import { Button } from '../Typography'

const Header = styled.header`
  padding: 2rem;
  max-width: 50rem;
  margin-left: auto;
  margin-right: auto;
  display: flex;
  flex-wrap: wrap;
  position: relative;
  z-index: 2;

  @media (max-width: 750px) {
    padding: 1rem 0;
    padding-bottom: 0rem;

    span {font-size: 0.9rem}
  }
`

const HeaderItem = styled.div`
  cursor: pointer;
  border: 1px solid var(--lilac);
  border-right: 0;
  flex: 1;
  text-align: center;

  transition: 0.2s ease-out color, 0.3s ease-out background-color, 0.2s ease-out border-color;

  &:last-of-type {
    border-right: 1px solid var(--lilac);
  }

  &:hover span {
    opacity: 0.5;
  }

  div {
    padding: 0.75rem 1rem;
  }

  @media (max-width: 750px) {
    div {padding: 0.75rem 0.25rem;}
  }

  ${({ selectedOption }) => {
    return selectedOption && css`
    color: var(--dark);
    background-color: var(--lilac);

    span {color: var(--dark)}
  `}
  }
`

const CardItem = ({
  title,
  i,
  selectedOptions,
  selectOption
}) => {
  const selectOptionFromCard = () => {
    selectOption(i)
  }

  const onKeyPress = e => {
    if (e.keycode === 13) {
      selectOptionFromCard()
    }
  }

  return (
    <HeaderItem selectedOption={selectedOptions.includes(i)}>
      <div
        onClick={selectOptionFromCard}
        role="button"
        tabIndex={i === 0 ? '0' : `-${i}`}
        aria-label="Card"
        onKeyDown={onKeyPress}
      >
        <Button>{title}</Button>
      </div>
    </HeaderItem>
  )
}


export default class CardHeader extends Component {
  render() {
    const { cards, selectedOptions, selectOption } = this.props

    const monoCards = cards.filter(card => card.arrayValue[1] === -1)
    return (
      <Header>
        {monoCards.map((card, i) => <CardItem
          key={i}
          i={i}
          selectOption={selectOption}
          selectedOptions={selectedOptions}
          {...card} />)}
      </Header>
    )
  }
}
