import React, { Component } from "react";
import styled from "styled-components";

import withIntersectionObserver from "../withIntersectionObserver";

import { H2, H3 } from "../Typography";
import CardHeader from "./CardHeader";
import CardView from "./CardView";
// import Gradient from '../SectionLanding/Gradient'

const CardChoices = styled.section`
  position: relative;
  padding: 5rem 2rem;
  padding-bottom: 0;
  width: 100%;
  height: 100vh;
  min-height: 800px;
  max-height: 900px;
  text-align: center;
  overflow: hidden;
  background-color: #0f061e;

  .gradient {
    bottom: -1rem;
  }

  @media (max-width: 1000px) {
    max-height: unset;
    min-height: unset;
    height: auto;
    
    padding: 2rem;
    padding-bottom: 0;
  }

  @media (max-width: 800px) {
    padding-top: 3rem;
    padding-left: 2rem;
    padding-right: 2rem;
  }

  
`;

class SectionChooseCards extends Component {
  constructor(props) {
    super(props);

    this.state = {
      selectedOptions: [0, -1],
      lastUpdatedIndex: 0,
    };
  }

  selectOption = (option) => {
    let { selectedOptions, lastUpdatedIndex } = this.state;
    const existsOptionIndex = selectedOptions.findIndex(
      (selectedOption) => selectedOption === option
    );
    const _selectedOptions = [...selectedOptions];

    if (existsOptionIndex >= 0) {
      _selectedOptions[existsOptionIndex] = -1;
      lastUpdatedIndex = existsOptionIndex;
      if (_selectedOptions[0] === -1 && _selectedOptions[1] === -1) {
        _selectedOptions[0] = 0;
        lastUpdatedIndex = 0;
      }
    } else {
      let unselectedIndex = selectedOptions.findIndex(
        (selectedOption) => selectedOption < 0
      );

      if (unselectedIndex >= 0) {
        _selectedOptions[unselectedIndex] = option;
        lastUpdatedIndex = unselectedIndex;
      } else {
        const indexToReplace = lastUpdatedIndex === 0 ? 1 : 0;
        _selectedOptions[indexToReplace] = option;
        lastUpdatedIndex = lastUpdatedIndex === 0 ? 1 : 0;
      }
    }
    this.setState({ selectedOptions: [..._selectedOptions], lastUpdatedIndex });
  };

  render() {
    const { cards } = this.props;
    const { selectedOptions } = this.state;
    return (
      <CardChoices>
        <H2 style={{ zIndex: 3, position: "relative" }} uppercase>
          Choose your cards
        </H2>
        <H3 style={{
          padding: "0 2rem",
          marginBottom: "1rem"
        }}>Select up to two Prisms to find your Skyweaver Hero.</H3>
        <CardHeader
          selectedOptions={selectedOptions}
          selectOption={(i) => {
            this.selectOption(i);
          }}
          cards={cards}
        />

        <CardView
          selectOption={(i) => {
            this.selectOption(i);
          }}
          selectedOptions={selectedOptions}
          cards={cards}
        />
      </CardChoices>
    );
  }
}

export default withIntersectionObserver(0.9)(SectionChooseCards);
