import React, { Component } from 'react'
import styled from 'styled-components'

import '../css/main.css'
import '../css/fonts.css'
import '../css/slider.horizontal.css'
import '../css/reset.css'

import staticContent from '../data'

import SeoHead from '../components/SeoHead'

import Header from '../components/Header'

import SectionLanding from '../components/SectionLanding'
import HexboundHomepageSection from 'components/HexboundInvasion/HexboundHomepageSection'
import SectionIntro from '../components/SectionIntro'
import SectionGameplay from '../components/SectionGameplay'
import SectionCardChoices from '../components/SectionCardChoices'
// import SectionOwn from '../components/SectionOwn'
// import SectionEarn from '../components/SectionEarn'
import SectionEarlyAccess from '../components/SectionEarlyAccess'
import SectionSpells from '../components/SectionSpells'

import SectionFeatureLeft from '../components/SectionFeatureLeft'
import SectionFeatureRight from '../components/SectionFeatureRight'


const Main = styled.main`
  width: 100%;
  overflow: hidden;
`

export default class IndexPage extends Component {
  state = {
    overlay: false,
    windowWidth: false,
  }

  componentDidMount() {
    this.attachEventListeners()
    this.handleResize()
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.handleResize)
  }

  attachEventListeners() {
    window.addEventListener('resize', this.handleResize)
  }

  handleResize = e => {
    this.setState({
      windowWidth: window.innerWidth,
    })
  }

  toggleOverlay = () => {
    this.setState({ overlay: !this.state.overlay })
  }

  render() {
    const { windowWidth } = this.state

    return (
      <>
        <SeoHead />
        <Main dataArticulateWidth={windowWidth}>
          {/* {overlay && <Overlay toggleOverlay={this.toggleOverlay} {...landingContent} />} */}

          <Header windowWidth={windowWidth} />

          <SectionLanding windowWidth={windowWidth} toggleOverlay={this.toggleOverlay} {...staticContent.sectionLanding} />
          <HexboundHomepageSection windowWidth={windowWidth} />
          <SectionIntro windowWidth={windowWidth} {...staticContent.sectionIntro} />
          <SectionGameplay windowWidth={windowWidth} {...staticContent.sectionGameplay} />
          <SectionCardChoices windowWidth={windowWidth} {...staticContent.sectionCards} />
          <SectionSpells {...staticContent.sectionSpells} windowWidth={windowWidth} />
          <SectionFeatureLeft windowWidth={windowWidth} {...staticContent.sectionFTP} />
          <SectionFeatureRight windowWidth={windowWidth} {...staticContent.sectionPTO} />
          <SectionFeatureLeft windowWidth={windowWidth} {...staticContent.sectionEternal} />
          <SectionFeatureRight windowWidth={windowWidth} {...staticContent.sectionCraft} />
          <SectionFeatureLeft windowWidth={windowWidth} {...staticContent.sectionOwn} />

          <SectionEarlyAccess {...staticContent.sectionEarlyAccess} windowWidth={windowWidth} />
        </Main>
      </>
    )
  }
}
