import { data as _data } from '.'

/**
 * Edit the altTitle and text fields to change how they appear
 * in the subthemes section for the Hexbound Invasion page
 */
const SUBTHEMES_TEXT = {
  strength: {
    altTitle: 'Strength - Rally The Forces of Armis!',
    text: `The Hexbound scourge is advancing further each day, Armis will need to rally and bolster its strongest soldiers if it hopes to survive the coming onslaught!`,
  },
  agility: {
    altTitle: 'Agility - Slash and Burn!',
    text: `Agility is doing whatever it takes to stay alive and win the day. Use your own health as a resource to move faster and hit harder, with cards like the Mighty Soulpyre Titan!`,
  },
  wisdom: {
    altTitle: 'Wisdom - Fortify your defenses to outlast your Enemies!',
    text: `Bolster allies' health while infecting your enemies deck with Blight cards, forcing them between being starved of resources or making use of the blights you’ve thrust upon them at great risk to their health!`,
  },
  heart: {
    altTitle: 'Heart - The Horde Overwhelms!',
    text: `While the Hexbound are not beholden to any Prism, nor anyone, the Heart Prism has been shown to influence them to some degree. Take control of the horde and set it loose upon your foes!`,
  },
  intellect: {
    altTitle: 'Intellect - Wield Mighty Spells!',
    text: `Intellect weaves some of the most powerful and forbidden magics that Sky has ever seen, calling down a torrent of arcane might to drown its foes, casting them into the deepest abysses of the dreamfield.`,
  },
}

const S3_BUCKET_URL = `https://s3.amazonaws.com/skyweaver.net/video/hexbound-invasion/`
const SUBTHEME_VIDEO_FILES = {
  strength: `Strength_Bulwark_of_Armis_Clip.mp4`,
  agility: `Agility_Soulpyre_Titan_Clip.mp4`,
  wisdom: `Wisdom_Blight_Clip.mp4`,
  heart: `Heart_Zomboid_Clip.mp4`,
  intellect: `Intellect_Tentacle_Eruption_Clip.mp4`,
}

const SUBTHEMES_ITEMS = _data.subthemes.items.map(item => ({
  ...item,
  ...SUBTHEMES_TEXT[item.title],
  video: { url: `${S3_BUCKET_URL}${SUBTHEME_VIDEO_FILES[item.title]}` },
}))

export const data = {
  // HOMEPAGE DATA
  homepage: {
    text: `The Hexbound spill forth, devouring all in their path, and blighting the land with their curse. Will you hold strong against the tide, or embrace its irresistible, infectious power?`,
  },
  // HERO DATA
  hero: {
    video: {
      show: true,
      source: 'https://www.youtube.com/embed/UJmGW_p2Wac?controls=0&autoplay=1',
    },
    copy: 'The shadow of the Hexbound has fallen across Sky, consuming all in its path, blighting the land with its curse. Will you hold strong against the tide, or embrace its irresistible, infectious power?',
  },
  // SECTIONS DATA
  sections: {
    // SLAY SECTION
    slay: {
      title: 'new ability: slay',
      description:
        'The hunger of the Hexbound has manifested through a new keyword ability, Slay! Slay can be found on both Units and Spells, and triggers when that card damages and destroys an enemy unit during your turn. Will you indulge in the horde’s dark hunger, or risk being consumed yourself?',
    },
    // SKYPASS SECTION
    skypass: {
      title: 'hexbound skypass',
      description: `The Skypass knows only one thing, power. Every battle, win or lose, is progress towards unlocking its fabled rewards. There are both free and premium rewards to unlock, but if you unlock it fully, prepare yourself for the accursed Hex Cardback.`,
    },
    // HEXFECTION SECTION
    hexfection: {
      title: 'the hexbound curse spreads!',
      description: `“With each passing day, the curse of the hexbound grows stronger. Seize its dark power for yourself!”

“Use Hexfection to infect Units with the Hexbound Curse, or summon Zomboids to tear the life from the enemy Hero. Which scourge will you inflict upon your foes!?”`,
    },
    // SUBTHEMES SECTION
    subthemes: {
      title: 'Adapt your Prism',
      subtitle: 'See how Prisms adjust to the Hexbound Invasion!',
      text: 'Hold strong and resist the Hexbound Horde or embrace its infectious Power? Learn more about the new cards for each Prism and gameplay clips below!',
      items: SUBTHEMES_ITEMS,
    },
    // REVEAL CALENDAR SECTION
    calendar: {
      title: 'Card Reveal Calendar',
      items: [
        {
          image: '/images/hexbound-invasion/calendar/gooddog.png',
          date: 'Jan 5',
          revealed: true,
          text: 'Good Dog revealed 4 cards at 14:00 EST',
          username: 'Good Dog',
          platform: 'Twitch',
          link: 'https://www.twitch.tv/videos/1698553968',
        },
        {
          image: '/images/hexbound-invasion/calendar/puza.png',
          date: 'Jan 6',
          revealed: true,
          text: 'Puza revealed 4 cards at 10:00 EST',
          username: 'Puza',
          platform: 'Twitch',
          link: 'https://www.twitch.tv/videos/1699330422',
        },
        {
          image: '/images/hexbound-invasion/calendar/cytus.png',
          date: 'Jan 7',
          revealed: true,
          text: 'Cytus revealed 4 cards at 8:00 EST',
          username: 'Cytus',
          platform: 'Twitch',
          link: 'https://www.twitch.tv/videos/1700294077',
        },
        {
          image: '/images/hexbound-invasion/calendar/naiman.png',
          date: 'Jan 8',
          revealed: true,
          text: 'Naiman revealed 4 cards at 9:00 EST',
          username: 'Naiman',
          platform: 'Twitch',
          link: 'https://www.twitch.tv/videos/1701340060',
        },
        {
          image: '/images/hexbound-invasion/calendar/seven.png',
          date: 'Jan 9',
          revealed: true,
          text: 'Seven revealed 4 cards at 9:00 EST',
          username: 'Seven',
          platform: 'Twitch',
          link: 'https://www.twitch.tv/videos/1702283849',
        },
        {
          image: '/images/hexbound-invasion/calendar/tuizz.png',
          date: 'Jan 10',
          revealed: true,
          text: 'Tuizz revealed 4 cards at 15:00 EST',
          username: 'Tuizz',
          platform: 'Twitch',
          link: 'https://www.twitch.tv/videos/1703460501',
        },
        {
          image: '/images/hexbound-invasion/calendar/xelasalex.png',
          date: 'Jan 11',
          revealed: true,
          text: 'Xelasalex revealed 4 cards at 12:30 EST',
          username: 'Xelasalex',
          platform: 'Twitch',
          link: 'https://www.twitch.tv/videos/1704220884',
        },
        {
          image: '/images/hexbound-invasion/calendar/gaara.png',
          date: 'Jan 12',
          revealed: true,
          text: 'Gaara revealed 4 cards at 14:00 EST',
          username: 'Gaara',
          platform: 'Twitch',
          link: 'https://www.twitch.tv/gaara',
        },
        {
          image: '/images/hexbound-invasion/calendar/noblesse.png',
          date: 'Jan 14',
          revealed: true,
          text: 'Noblesse revealed 4 cards at 14:00 EST',
          username: 'Noblesse',
          platform: 'Twitch',
          link: 'https://www.twitch.tv/videos/1707347684',
        },
        {
          image: '/images/hexbound-invasion/calendar/blankhandle.png',
          date: 'Jan 15',
          revealed: true,
          text: 'blankHandle revealed 4 cards at 23:00 EST',
          username: 'blankHandle',
          platform: 'Twitch',
          link: 'https://www.twitch.tv/videos/1708855840',
        },
      ],
    },
    // EXPLORE SECTION
    explore: {
      title: 'Explore the full\nexpansion pack',
      text: 'See for yourself the vast possibilities that the Hexbound Invasion brings to the world of Skyweaver.',
    },
    // JOIN SECTION
    join: {
      title: 'Join the battle',
      text: 'Join our Discord server and subscribe to our Subreddit to share your ideas. If you haven’t already, be sure to create a skyweaver account now and check out all Sky has to offer!',
    },
  },
}
