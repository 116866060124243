import React, { PureComponent } from "react";
import styled from "styled-components";
import { withPrefix } from "gatsby";

import IntroText from "./IntroText";
import Buttons from "./Buttons";
import EmbededPlayer from "./EmbededPlayer";
import Gradient from "./Gradient";
import Quotes from "./Quotes"

const Landing = styled.section`
  padding-top: 20rem;
  overflow: hidden;
  padding-bottom: 22rem;
  min-height: 54rem;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  overflow: hidden;
  background-color: #1e133d;

  @media (max-width: 750px) {
    min-height: 50rem;
    padding-bottom: 8rem;
    overflow: hidden;
    align-items: flex-start;
    padding-top: 4rem;
    height: calc(100vh - 1rem);
  }
`;

const Background = styled.div`
  position: absolute;
  top: 0; left: 0;
  height: 100%;
  width: 100%;
  
  background-image: url('${withPrefix("/images/0-intro/Header@2x.jpg")}');
  background-size: cover;
  background-position: center center;

  @media (max-width: 750px) {
    background-position: top center;
    background-image: url('${withPrefix("/images/0-intro/MobileHeader.jpg")}');
  }

  @media (max-width: 650px) {
    top: 0rem;
    height: 100%;
  }
`;

const Text = styled.div`
  width: 100%;
  max-width: 50rem;
  z-index: 6;
`;

class SectionLanding extends PureComponent {
  render() {
    const { quotes, title, earlyAccessCTA } = this.props;
    return (
      <>
        <Landing id="landing">
          <Text>
            <IntroText title={title} />
            {/* <Buttons earlyAccessCTA={earlyAccessCTA} /> */}
            <EmbededPlayer />
            <Buttons earlyAccessCTA={earlyAccessCTA} />
          </Text>

          <Gradient hideOnMobile gradientDirection="top" />
          <Gradient />

          <Background />
        </Landing>

        {/* <Quote quote={quote} /> */}
        <Quotes quotes={quotes} />
      </>
    );
  }
}

export default SectionLanding;
