import React, { PureComponent } from "react";
import styled from "styled-components";
import { withPrefix } from "gatsby";

import { Button as ButtonText } from "../Typography";

import PlayNowButton from "components/Header/PlayNowButton";

const topRow = [
  {
    title: "windows",
    icon: "WindowsButton.png",
    link: "https://download-win.skyweaver.net"
  },
  {
    title: "mac",
    icon: "MacButton.png",
    link: "https://download-mac.skyweaver.net"
  },
  {
    title: "linux",
    icon: "LinuxButton.png",
    link: "https://download-linux.skyweaver.net"
  },
  {
    title: "googleplay",
    icon: "GooglePlayButton.png",
    link: "https://download-android.skyweaver.net"
  },
  {
    title: "mac",
    icon: "AppStoreButton.png",
    link: "https://download-ios.skyweaver.net"
  }
]


const Logos = styled.div`
display: flex;
flex-wrap: wrap;
margin-bottom: 0.5rem;
margin-top: 2rem;
justify-content: center;


@media (max-width: 750px) {
  padding: 0 2rem;
  max-width: 28rem;
  margin-left: auto;
  margin-right: auto;
}

`

const LogoEl = styled.div`
  height: 3.2rem;
  margin-right: 0.5rem;
  margin-bottom: 0.5rem;
  line-height: 3.5;
  transition: 0.3s ease-out opacity;

  &:hover {
    opacity: 0.5;
  }
  img {
    width: auto;
    height:100%;
    vertical-align: middle;
  }
`


const Logo = ({ title, icon, link }) =>
  <a target="_blank" rel="noopener noreferrer" href={link}>
    <LogoEl className='logo-el'>
      <img alt={title} src={withPrefix(`/images/1-about/${icon}`)} />
    </LogoEl>
  </a>



const Buttons = styled.div`
  margin-left: auto;
  margin-right: auto;
  padding: 1rem 2rem;
  text-align: center;
  flex-direction: column;
  position: relative;
  z-index: 1;


  button {
    width: 100%;

    img {
      width: 100%;
    }
  }

  ${ButtonText} {
    font-weight: bolder;
    font-size: 1.5rem;
  }

  .join-discord {
    display: none;
  }

  @media (max-width: 750px) {
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    width: 100%;
    bottom: 4rem;
    padding: 0;
    
    .button {
      width: 100%;
      margin-bottom: 1rem;
      display: none;
    }

    .join-discord {
      border-radius: 0.25rem;
      display: inline-block;
      width: 100%;
      border-color: var(--dark);
      color: #fff;

      span {
        color: var(--dark);
      }
      svg {
        fill: var(--dark);
      }
    }
  }

  .content {
    padding: 18px 0 6px 0;
  }

  .login {
    color: #fff;
    font-weight: 700;
    font-size: 12px;
    cursor: pointer;
    text-decoration: underline;
  }
`;

export default class ButtonsEl extends PureComponent {
  render() {
    return (
      <Buttons>
        <div className="button">
          <PlayNowButton />
        </div>


        <Logos>
          {topRow.map(logo => <Logo {...logo} key={logo.title} />)}
        </Logos>
      </Buttons>
    );
  }
}
