import React from "react";
import styled from "styled-components";

import Image from "../Image";

const Intro = styled.div`
  max-width: 26rem;
  padding: 2rem;
  padding-bottom: 0;
  margin-left: auto;
  margin-right: auto;
  position: relative;
  z-index: 30;

  @media (max-width: 750px) {
    padding: 2rem;
    padding-bottom: 0;
  }
`;

const Logo = styled.div`
  padding: 4rem 0 1rem 0;
  z-index: 10;
  position: relative;

  @media (max-width: 750px) {
    padding: 2rem 1rem;
    padding-bottom: 0;
  }
`;


const Section = () => (
  <Intro>
    <Logo>
      <Image src="/images/skyweaver-beta.svg" alt="Skyweaver Logo" />
    </Logo>
    {/* <Text>
      <H1 color='#fff'>{title}</H1>
    </Text> */}
  </Intro>
);

export default Section