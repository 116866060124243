import React, { PureComponent } from "react";
import styled from "styled-components";

import Logos from "./Logos";
import SectionText from "../SectionText";
import { H2, H3 } from "../Typography";
import Image from "../Image";

const Intro = styled.section`
  position: relative;
  overflow: visible;

  @media (max-width: 750px) {
    margin-top: 0;
    background-color: #6b55a2;
    display: flex;
    flex-wrap: wrap;

    .section-text {
      order: 2;
    }
  }
`;

const Background = styled.div`
  height: 55vw;
  pointer-events: none;


  img, figure {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  @media (max-width: 750px) {
    height: auto;
    width: 100%;
    order: 1;
    position: relative;

    &::after {
      content: " ";
      position: absolute;
      bottom: -1%;
      left: 0;
      height: 30%;
      width: 100%;
      z-index: 10;
      background: linear-gradient(to top, #6b55a2, rgba(107, 85, 162, 0));
    }
  }
`;

class SectionIntro extends PureComponent {
  render() {
    const { title, text } = this.props;

    return (
      <>
        <Intro>
          <SectionText mobilePosition="bottom" position="left">
            <H2 color="#fff" uppercase>
              {title}
            </H2>
            <H3>{text}</H3>
            <Logos />
          </SectionText>

          <Background>
            <Image mobileSrc="/images/1-about/mobile.jpg" src="/images/1-about/desktop.jpg" />
          </Background>
        </Intro>
      </>
    );
  }
}

export default SectionIntro;
