import React, { PureComponent } from "react";
import styled from "styled-components";

import GameplaySlideshow from "./GameplaySlideshow";
import Image from "../Image";


const Background = styled.div`
  pointer-events: none;
  height:100%;
  width:100%;

  img, figure {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  @media (max-width: 750px) {
    height: auto;
    width: 100%;
    order: 1;
    position: relative;

    figure, img {
      width: 100%;
      height: auto;
      object-fit: unset;
    }
  }
`;


const Gameplay = styled.section`
  height: 50rem;
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  width: 100%;
  z-index: 10;
  background-color: var(--bgLight);
  
  @media (min-width: 1200px) {
    height: 56rem;
  }

  @media (max-width: 1024px) {
    height: 45rem;
  }

  @media (max-width: 800px) {
    height: 43rem;
  }

  @media (max-width: 750px) {
    background-color: #0f061e;
    height: inherit;
    padding-bottom: 2rem;
  }
`;

const EmptySpace = styled.div`
  width: 55%;

  @media (max-width: 800px) {
    width: 50%;
  }

  @media (max-width: 750px) {
    display: none;
  }
`;

class SectionGameplay extends PureComponent {
  render() {
    return (
      <Gameplay id="about">
        <Background>
          <Image mobileSrc="/images/2-gameplay/mobile.jpg" src="/images/2-gameplay/desktop.jpg" />
        </Background>

        <EmptySpace />
        <GameplaySlideshow {...this.props} />

      </Gameplay>
    );
  }
}

export default SectionGameplay;
